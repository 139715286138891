import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface SearchProduct {
    [id: string]: SearchVariant[];
}

export interface SearchVariant {
    baseProductId: string;
    variantId: string;
    colorHex: string;
    colorName: string;
    image: string;
    name: string;
    size: string;
    price: VariantPrice;
    stock: VariantStock;
}

export interface VariantStock {
    quantityInStock: number;
    overbookedQuantity: number;
    purchaseOrders: PurchaseOrder[];
}

interface PurchaseOrder {
    deliveryDate: string;
    quantityInPurchaseOrder: number;
}

interface VariantPrice {
    campaignPrice: number;
    formattedCampaignPrice: string;
    price: number;
    formattedPrice: string;
}

export const productSearchApi = createApi({
    reducerPath: 'productSearchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/product-search',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        productSearch: builder.mutation<SearchProduct, string>({
            query: query => ({
                url: `?query=${encodeURIComponent(query)}`,
                method: 'POST',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
    }),
});

export const { useProductSearchMutation } = productSearchApi;
