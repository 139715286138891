import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface SearchPerson {
    id: string;
    systemId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

export const personSearchApi = createApi({
    reducerPath: 'personSearchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/person/search',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        personSearch: builder.mutation<SearchPerson[], string>({
            query: query => ({
                url: `?query=${encodeURIComponent(query)}`,
                method: 'POST',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
    }),
});

export const { usePersonSearchMutation } = personSearchApi;
