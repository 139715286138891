import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlphaMiniCartModel } from 'Types/Litium';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import { cartApi } from 'Services/cartApi';

interface IMiniCart {
    miniCartModel: {
        orderDetails: IAlphaMiniCartModel['orderDetails'];
        shoppingCart: IAlphaMiniCartModel['shoppingCart'];
        translations: IAlphaMiniCartModel['translations'];
        checkoutPage: IAlphaMiniCartModel['checkoutPage'];
    };
    setCartOpen: boolean;
    hasAnythingInCart: boolean;
}

export const initialState = {
    miniCartModel: {
        orderDetails: null,
        shoppingCart: null,
    },
    setCartOpen: false,
    hasAnythingInCart: false,
};

const slice = createSlice({
    name: 'cart',
    initialState: mergeStateWithPreloaded('cart', initialState) as IMiniCart,
    reducers: {
        toggleCartOpen(state, action: PayloadAction<IMiniCart['setCartOpen']>) {
            state.setCartOpen = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            cartApi.endpoints.addToCart.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
        builder.addMatcher(
            cartApi.endpoints.getCart.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
        builder.addMatcher(
            cartApi.endpoints.hasCart.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
        builder.addMatcher(
            cartApi.endpoints.updateCartRow.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
        builder.addMatcher(
            cartApi.endpoints.replaceCart.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
    },
});

export const { toggleCartOpen } = slice.actions;

export default slice.reducer;
