import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface User {
    id: string;
    systemId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

export const impersonateApi = createApi({
    reducerPath: 'impersonateApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/user/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        impersonate: builder.mutation<User, string>({
            query: query => ({
                url: `impersonate?personSystemId=${encodeURIComponent(query)}`,
                method: 'POST',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
        deimpersonate: builder.mutation<User, void>({
            query: () => ({
                url: `deimpersonate`,
                method: 'POST',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
    }),
});

export const { useImpersonateMutation, useDeimpersonateMutation  } = impersonateApi;
